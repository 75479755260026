.left-container {
  font-family: "Calibri";
  border-right: 1px solid #bbbdba;
  height: calc(100vh - 55px);
  overflow: auto;
}
.left-container h3 {
  font-size: 1.4rem;
  padding: 2rem 1rem;
  margin: 0;
  font-weight: 800;
  background-color: #b23cfd;
  color: white;
  text-align: center;
}
.left-container ul {
  margin: 1px 0;
}
.left-container li {
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5rem 0;
}
.left-container li:hover {
  background-color: #b23cfd;
  color: white;
  font-weight: 800;
}

.left-container span {
  margin: 0 10%;
}
.category-list li.active {
  background-color: #b23cfd;
  color: #ffeb41;
  font-weight: 800;
}
@media (min-width: 992px) {
  .left-container span {
    margin: 0 15%;
  }
}
