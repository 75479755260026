.modal-container {
  position: absolute;
  top: 50%;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 2px;
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.modal-container .close-btn {
  margin: 0;
  float: right;
  cursor: default;
}

.modal-container h6 {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}

.modal-container.share-modal {
  height: 150px;
}

.modal-container.share-modal>div>p {
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.modal-container.share-modal>div>p span:nth-child(2) {
  background-color: #9e9e9e;
  padding: 4px 3px;
  border-radius: 2px;
}

.modal-container.share-modal>div>p button {
  margin-left: 5px;
}

.modal-container.share-modal .icon-container {
  display: flex;
  justify-content: space-evenly;
}

/* EDIT USER DETAILS/ */
.modal-container.edit-user {
  height: 350px;
  top: 50%;
}

.edit-user button:last-of-type {
  margin-left: 0.4rem;
}

/* ADD VIDEO DIALOG */
.modal-container.add-video {
  height: 350px;
}


@media (min-width: 600px) {
  .modal-container {
    width: 350px;
  }
}

@media (min-width: 567px) {
  .modal-container.share-modal {
    width: 400px;
  }
}