.account-page{
    min-height: calc(100vh - 106px);
    padding-top: 5px;
    font-family: var(--font-roboto);
}

@media screen and (max-width: 600px){
    .account-page{
        min-height: calc(100vh - 103px);
    }
}
@media screen and (max-width: 495px){
    .account-page{
        min-height: calc(100vh - 121px);
    }
}