.about-container h2 {
  margin: 0;
  color: #ab3535;
  font-size: 3.3rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.about-container h2,
.contact-us h3 {
  font-family: var(--font-stalemate);
}

.about-container .rows {
  padding: 2rem 0;
  margin-bottom: 0;
}

.about-container svg {
  width: 90%;
}

.contact-us {
  padding: 1rem 0 2rem 0;
  margin-bottom: 0;
  text-align: center;
  text-align: -webkit-center;
  background-color: var(--red-color);
}

.contact-us h3 {
  font-size: 3rem;
  padding-top: 1rem;
  margin: 0;
  color: white;
  text-decoration: underline;
}

.contact-us a {
  display: block;
}

.contact-us .icon-tab {
  display: flex;
  justify-content: center;
  margin-right: 1.5rem;
  width: 9rem;
  padding: 0.5rem 0.4rem;
  background: #fffcfc;
  box-shadow: 1px 4px 3px 1px rgba(125, 125, 125, 0.25);
  border-radius: 2px;
}

.contact-us .icon-tab span {
  margin-right: 0.7rem;
}

.contact-us .icon-tab.Gmail {
  padding: 0.5rem 1.3rem;
  color: #c55353;
  margin-right: 0;
}

.contact-us .icon-tab.Instagram {
  color: #d08d8d;
}

.contact-us .icon-tab svg {
  width: 20px;
  height: 19px;
  margin-right: 7px;
}

.Facebook,
.Linkedin strong {
  color: #6473ff;
}

.on-mobiles > div {
  margin: 1rem auto;
}

.about-container .hidden {
  display: block;
}

@media (min-width: 768px) {
  .about-container .hidden {
    display: block;
  }
}

@media (min-width: 500px) {
  .contact-us .on-tablet {
    padding: 1rem 0;
    justify-content: center;
  }
  .contact-us h3 {
    padding: 1rem 0;
  }
}

@media (max-width: 500px) {
  .about-container .xs12 {
    text-align: center;
  }
  .about-container h2 {
    margin-top: 1rem;
  }
}

@media (max-width: 599px) {
  .on-mobiles .icon-tab {
    margin: 1rem auto;
  }
  .on-mobiles .icon-tab.Gmail {
    margin-right: auto !important;
  }
}
