.page-label{
    padding: 5px 2px;
    background-color: #E0E0E0;
}
.page-label p{
    text-align: center;
    font-family: var(--font-girl-nd);
    letter-spacing: 2px;
    font-weight: 700;
    color: rgba(83,83,83);
    margin: 0;
}


@media (min-width: 768px){
    .page-label{
        display: none;
    }
}