:root {
  --pink-color: #ee6e73;
  --navbar-color: #fefefe;
  --user-menu-color: rgb(255, 180, 180);
  --category-color: #b23cfd;
  --red-color: #ff6464;
  --red-hover: #ce3c3c;
  --font-girl-nd: "The Girl Next Door", cursive;
  --font-short-stack: "Short Stack", cursive;
  --font-stalemate: "Stalemate", cursive;
  --font-roboto: 'Roboto', sans-serif;
  font-family: "Short Stack", cursive;
}

/* UPDATED MATERAILIZE CLASSES */
@media screen and (max-width: 500px) {
  .row .col.xs12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .rows {
    flex-wrap: wrap;
  }
}


/* Footer  */

footer {
  color: white;
  background-color: #383131;
  font-family: var(--font-roboto-c);
  padding: 5px 0;
}

footer p {
  display: inline-block;
  margin: 0 0 0 1rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  top: -5px;
  right: 105%;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

/* HANDLE FONT-SIZE AND DIMENSION USING rem STYLING */

@media screen and (max-width: 600px) {
  :root {
    font-size: 12px;
  }
}

@media screen and (min-width: 1900px){
  :root{
    font-size: 18px;
  }
}

/* ROOT STYLING NEEDS IN CHUNKS PAGES */

.icons-container {
  display: flex;
  padding: 0.4rem !important;
}

.icons-container+div {
  justify-content: space-evenly;
}

.icons-container+div p {
  margin: 0.1rem 0;
}
.video-card {
  margin: 0 4%;
}