.language-container {
  justify-content: space-around;
  font-family: "Calibri";
  font-size: 1.2rem;
  background-color: #a06ab4;
  color: white;
  font-weight: 600;
  text-shadow: 0px 0px 1px #323232;
}
.language-container ul {
  margin: 0;
}
.language-container li,
.language-container span {
  cursor: pointer;
  display: inline-block;
  margin: 0 2%;
}
.language-container .icons {
  width: 15%;
}
.language-container .search {
  width: 20%;
}
.language-container span {
  margin: 0 3%;
}
.language-container li:hover,
.language-container span:hover {
  color: #ffeb3b;
}
.language-container > div {
  margin: 0 2%;
}
.language-container > div:first-child {
  width: 35%;
}
.language-container label {
  display: none;
}
.language-container .search input {
  width: 50%;
  background-color: var(--navbar-color);
  box-shadow: 0px 0px 0px 0.5px rgba(250, 250, 250, 0.22);
  border-radius: 4px;
  font-size: 0.9rem;
  color: #a06ab4;
  border: none;
  transition: width 0.25s;
  padding: 0.3rem 0.3rem 0.4rem 0.4rem;
}
.language-container input:focus {
  width: 75%;
  outline: none;
}

.language-container input::placeholder {
  font-size: 0.9rem;
  vertical-align: center;
  color: #8d8d8d;
}
.language-container button {
  font-size: 1.5rem;
  padding: 0.2rem 0.2rem 0.1rem 0.2rem;
  border: none;
  margin-left: 0.2rem;
  border-radius: 4px;
  color: #a06ab4;
  background-color: var(--navbar-color);
}
.language-container button:hover {
  background-color: #a06ab4;
  border: 1px solid var(--navbar-color);
  color: var(--navbar-color);
}
.language-container .active {
  color: #ffeb3b;
}
@media (min-width: 992px) {
  .language-container li {
    margin: 0 3%;
  }
}
