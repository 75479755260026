.playing-container {
  overflow-y: auto;
}
.player-card > div {
  margin-top: 0;
  margin-bottom: 0;
}
/* below is second card in player-card */
.player-card .below {
  padding-top: 53vw;
}
.below .title-div {
  padding: 0.4rem 0.5rem;
}
.player-card .player {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 53vw;
}
#playeriframe {
  height: 100%;
}

/* DEScription h6 */
.player-card h6 {
  font-weight: 800;
  margin: 0;
}
.player-card .description {
  padding: 5px 10px;
}
.player-card .description + div {
  padding: 5px 10px;
  overflow-x: auto;
}
@media (min-width: 400px) {
  .player-card .title-div {
    height: 50px;
  }
}
@media (min-width: 576px) {
  .player-card .player {
    position: unset;
    height: 34vw;
  }
  .player-card .below {
    padding-top: 5px;
  }
  .playing-container {
    display: flex;
    overflow-y: auto;
    justify-content: space-evenly;
  }
  .player-card > div {
    margin-top: 0.15rem;
  }
  .playing-container > div:first-child {
    width: 70%;
  }
  .playing-container .side-list {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .player-card .title-div {
    height: 60px;
  }
}
