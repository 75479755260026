.login-container {
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.login-container label {
  font-size: 1rem;
  color:#212121;
}
.login-container .password{
  position: relative;
  margin-bottom: 2rem;
}
.login-container .eye-icon{
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.btn-container {
  text-align: center;
  margin-top: 5px;
}

.btn-container button {
  width: 50%;
  text-align: center;
  padding: 0 6%;
}

.social-btn {
  width: 50%;
  margin: auto;
  background-color: var(--pink-color);
  padding: 6px 6%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.social-btn svg {
  width: 19px;
  height: 19px;
}

.social-btn:hover {
  background-color: var(--red-hover);
}

.social-btn {
  color: white;
  font-size: 14px;
}

@media (min-width: 600px) {
  .login-container {
    width: 65%;
  }

  .btn-container button {
    padding: 0 10%;
  }

  .social-btn {
    padding: 7px 8%;

  }
}

@media (min-width: 1200px) {
  .login-container {
    width: 50%;
  }
}