.feedback-list {
  background-color: #9e9e9e;
  height: 300px;
  max-height: 350px;
  overflow-y: auto;
}
.feedback-list .feedback-card {
  display: flex;
  padding: 0px;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 17%);
  border-radius: 5px;
}
.feedback-list .feedback-card > div:nth-child(1) {
  width: 30%;
  text-align: center;
  border-right: 0.2px solid rgba(0, 0, 0, 0.09);
  padding: 0.3rem 0.5rem;
}
.feedback-list .feedback-card > div:nth-child(2) {
  padding: 0.3rem 0.5rem;
  width: 70%;
}
