.below .icons-container a {
  text-transform: none !important;
  color: #039be5 !important;
  margin-right: 0 !important;
}
.icons-container .first,
.icons-container .second {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.icons-container .second {
  width: 20%;
}
.icons-container .first {
  width: 80%;
  justify-content: space-between;
}
.icons-container .first p {
  margin: 0;
}
.icons-container .first strong {
  color: #039be5;
  font-weight: 500;
}
