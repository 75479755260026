.user-details{
    text-align: center;
    
}
.user-details .left{
    font-size: 1.17rem;
    margin-top: 3rem;
    text-align: left;
}
.user-details .right{
    margin-top: 1.5rem;
}
.user-details .left span,.user-details .left strong{
    width: 50%;
}
.user-details .left .blue-btn{
    padding: 0px 3rem;
}
.user-details .left strong{
    font-size: 1.2rem;
    display: inline-block;
}
