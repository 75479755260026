.feedback-container h2 {
  font-family: var(--font-stalemate);
}

.feedback-container h2 {
  margin: 0;
  color: #ab3535;
  font-size: 3.3rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.feedback-container .rows {
  padding: 2rem 0;
  margin-bottom: 0;
}

.feedback-container > div:first-child {
  text-align: center;
}
.feedback-container > div:nth-child(2) {
  padding-bottom: 0;
}

.feedback-container svg {
  width: 90%;
  height: 200px;
}

.feedback-container .fb-btn {
  border: none;
  overflow: hidden;
}

.fb_block {
  background-color: rgba(100, 172, 137, 0.15);
  padding-left: 3rem;
}

.fb_block h4 {
  margin: 1.5rem 0px;
  font-size: 1.8rem;
  color: #6c63ff;
  font-weight: 600;
}

.fb_block iframe {
  width: 200px;
}

.feedbackform {
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 20px;
  background-color: rgb(15 15 15 / 74%);
}

.feedbackform label {
  font-size: 1.4rem;
  color: white;
  margin: 0.8rem 0;
}

.feedbackform textarea {
  background: white;
  height: 40%;
  margin-bottom: 1rem;
}

.feedbackform button {
  margin-left: auto;
  padding: 0.7rem 1rem;
  background-color: rgb(31 163 42 / 77%);
  box-shadow: 0px 2px 1px 1px rgba(16, 51, 10, 0.25);
  border: none;
  color: white;
}

@media (min-width: 500px) {
  .feedback-container > div:first-child {
    text-align: initial;
  }
  .fb_block {
    height: 200px;
  }
  .fb_block h4 {
    font-size: initial;
  }
}

@media (min-width: 1900px){
  .fb_block,.feedbackform{
    height: 250px;
  }
}