.full-page.page-404 img{
    width: 100%;
}
.full-page.page-404 p{
      font-size: 1.5rem;
      font-weight: 600;
}
.full-page.page-404 p a{
    text-shadow: none;
    text-decoration: underline;
    color:#353c8b
}