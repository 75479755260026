/* MOBILE FIRST DESIGN */
nav {
  background-color: var(--navbar-color);
  height: 50px;
  line-height: 50px;
  justify-content: space-around;
}
.logo {
  max-width: 40px;
  max-height: 40px;
}
nav h1 {
  display: inline-block;
  color: var(--pink-color);
  font-family: var(--font-girl-nd);
  font-size: 3rem;
  font-weight: 500;
  margin: 0.5rem 0 0 0.5rem;
}
nav > div:nth-child(2) {
  display: flex;
  align-items: center;
  cursor: default;
}
.menu-btn {
  width: 40px;
  height: 75%;
}
.user-btn {
  width: 40px;
  height: 40px;
}
.user-btn .letter{
  width: 40px;
  height: 40px;
  background-color: #b57215;
  position: relative;
}
.user-btn .letter p{
  margin:0;
  color: black;
  cursor: pointer;
  line-height: 1;
  position: absolute;
  font-weight: 700;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

nav li a {
  color: var(--pink-color);
  padding: 0 10px;
}

.hide-on-mobile {
  display: none;
}
.login-btn {
  background-color: var(--pink-color);
  color: white;
  font-family: var(--font-short-stack);
  font-weight: 400;
}
.login-btn:hover {
  background-color: #ce3c3c;
}
.login-btn i {
  line-height: 34px !important;
  font-size: 1.2rem !important;
  margin-right: 1px;
}

/* USER MENU on clicking user-btn */
.user-menu {
  display: none;
  --black-10: #101010;
  position: absolute;
  width: 200px;
  height: 300px;
  right: 0;
  top: 50px;
  background-color: var(--user-menu-color);
  border: 1px solid #bcbcbc;
  border-radius: 10px 0px 0px 10px;
}

.user-menu li {
  padding-top: 10px;
  width: 100%;
}
.user-menu li.user{
  justify-content: space-evenly ;
}

.user-menu h2 {
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 5px 0 5px;
  color: var(--black-10);
  font-family: var(--font-short-stack);
}
.user-menu img {
  width: 50px;
  height: 50px;
}

.user-menu a {
  line-height: initial;
  width: 75%;
  padding: 8px 0;
  text-align: center;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.35);
  border-radius: 25px;
  box-shadow: 0px 1px 1px 0px rgba(223, 223, 223, 0.64);
  background-color: var(--navbar-color);
  color: var(--black-10);
  font-family: var(--font-short-stack);
}
.user-menu a:hover {
  color: var(--navbar-color);
  background-color: var(--pink-color);
  border-color: var(--navbar-color);
  box-shadow: 0px 1px 1px 0px rgba(235, 111, 111, 0.973);
}
.user-menu a:active {
  background-color: var(--pink-color);
  color: var(--navbar-color);
}
.home-menu,
.user-menu {
  z-index: 992;
}
/* HOME MENU */
.home-menu {
  display: none;
  --black-10: #101010;
  position: absolute;
  width: 200px;
  height: 300px;

  left: 0;
  top: 50px;
  background-color: var(--navbar-color);
  border: 1px solid #bcbcbc;
}
.home-menu li {
  width: 100%;
  text-align: center;
  color: var(--pink-color);
  font-size: 1.4rem;
  font-family: var(--font-girl-nd);
  font-weight: 500;
  letter-spacing: 0.2rem;
}
.home-menu li a{
  font-size: 1.3rem;
}
.home-menu li:hover a,.home-menu li.active {
  color: var(--navbar-color);
  background-color: var(--pink-color);
  font-weight: 600;
}
.home-menu li.active a{
  color: white;
}
.home-menu .custom-select{
  width: 90%;
  margin: auto;
  margin-top: 0.6rem;
}
.home-menu .custom-select label{
  top:-35px;
  left:0;
  font-size: 1rem;
  color:var(--pink-color)
}
.show-on-mobile {
  display: block;
}
/* Class used with javascript to show/hide menu's */
.show-menu {
  display: block;
}
@media screen and (min-width: 600px) and (min-height: 600px) {
  nav {
    height: 60px;
    line-height: 60px;
  }
}
@media screen and (min-width: 768px) {
  nav h1 {
    margin-top: 0.8rem;
  }
  .logo {
    max-width: 50px;
    max-height: 50px;
  }
  nav li a {
    font-size: 1.1rem;
    padding: 0 15px;
  }
  .show-on-mobile {
    display: none;
  }
  .hide-on-mobile {
    display: block;
  }
}
