.share-page .buttons {
    text-align: center;
    padding: 3px;
}

.share-page button {
    margin-top: 0.5rem;
}

.share-page button[type="submit"] {
    margin-right: 1rem;
}

.share-page .error-tag {
    text-align: left;
    color: red;
}

.share-page .sharev-list {
    padding: 4px 0px;
    overflow-y: auto;
    max-height: 78vh;
}

.share-page .sharev-list img {
    width: 100%;
    margin-top: 4px;
}

.share-page .sharev-list .grey-b {
    background-color: #f8f8ff;
}

.share-page .sharev-list .title {
    height: 25px;
}

.share-page .sharev-list .status-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.share-page .sharev-list .status-bar>div {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.4rem;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 1rem;
}

.share-page .sharev-list .status-bar>div i {
    font-size: 1.55rem;
}

.share-page .sharev-list .status-bar>div.waiting {
    background: #C3C575;
    border: 1px solid #C0C243;
}

.share-page .sharev-list .status-bar>div.accepted {
    background: #86CF7A;
    border: 1px solid #256723;
}

.share-page .sharev-list .status-bar>div.declined {
    background-color: #cc8c8c;
    border: 1px solid #3f0101;

}

@media (max-width: 600px) {
    .share-page button {
        height: 30px;
        line-height: 30px;
    }

    .share-page .sharev-list h6 {
        font-size: 1rem;
    }

    .share-page .status-bar small {
        width: 25.4px;
        height: 25.4px;
    }

    .share-page .status-bar small i {
        line-height: 27.4px;
    }

    .share-page .sharev-list .status-bar>div i {
        font-size: 1.35rem;
    }
}

@media (min-width: 400px) {
    .share-page .sharev-list .title {
        height: 32px;
    }
}

@media (min-width: 500px) {
    .share-page .sharev-list .title {
        height: 42px;
    }
}

@media (min-width: 790px) {
    .share-page .sharev-list .title {
        height: 65px;
    }
}