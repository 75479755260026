.home-container {
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 100%;
  height: 100vh;
  overflow: hidden;
}
header {
  grid-area: 1 / 1 / span 1 / span 2;
}
.left-container {
  display: none;
}

.videos-container {
  grid-area: 2/1 / span 1 / span 1;
  overflow: hidden;
  display: grid;
  grid-template-rows:100%;
}
.videos-container.show-label{
  grid-template-rows:30px 1fr;
}
.language-container {
  display: none;
}
.video-list {
  /* No need to specify grid area because of 100% */
  height: calc(100vh - 50px); /* height of both nav is used*/
  overflow: auto;
}
@media screen and (min-width: 600px) and (min-height: 600px) {
  .home-container {
    grid-template-rows: 60px 1fr;
  }
}
@media screen and (min-width: 768px) {
  .home-container {
    grid-template-columns: 20% 1fr;
  }
  /* In home-container */
  .left-container {
    display: block;
    grid-area: 2/ 1 / span 1 / span 1;
  }
  .videos-container {
    grid-area: 2/ 2 / span 1 / span 1;
    display: grid;
  }
.videos-container, .videos-container.show-label{
  grid-template-rows: 40px 1fr;

}
  /* In videos-container */
  .language-container {
    display: flex;
    grid-area: 1/1 / span 1 / span 1;
  }
  .video-list {
    grid-area: 2/1 / span 1 / span 1;
    height: calc(100vh - 100px);
  }
}
@media screen and (min-width: 1049.55px) {
  .home-container {
    grid-template-columns: 15% 1fr;
  }
}
