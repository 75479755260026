

/* .video-card > div {
  height: 93%;
} */

.video-card img {
  max-height: 158px;
}

.video-card p {
  color: rgb(34 34 34/76%);
}

.icons-container {
  display: flex;
  padding: 0.4rem !important;
}

.title {
  height: 4.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.icons-container a {
  cursor: pointer;
}

.backdrop {
  background-color: red;
}

.icons-container a:hover {
  color: rgb(34 34 34 / 76%);
}

.icons-container+div {
  justify-content: space-evenly;
}

.icons-container+div p {
  margin: 0.1rem 0;
}

.date-and-channel p {
  flex: 1;
  font-size: 0.9rem;
}

.channel-title{
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

/* DROP-Down */

#more-list li {
  min-height: none;
}

#more-list p {
  display: flex;
  padding: 0.7rem 0;
  justify-content: space-evenly;
  align-items: center;
}


@media screen and (min-width: 400px) {
  .video-card {
    margin: 10px 8%;
  }
}

@media screen and (min-width: 500px) {
  .video-list {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-auto-rows: max-content;
  }

  .video-card {
    margin: 0 1%;
  }
}

@media screen and (min-width: 768px) {
  #more-list p {
    padding: 1rem 0;
  }
}

@media screen and (min-width: 850px) {
  .video-list {
    grid-template-columns: repeat(3, 33%);
  }
}

@media screen and (min-width: 1050px) {
  .video-list {
    grid-template-columns: repeat(4, 25%);
  }
}

@media screen and (min-width: 1500px) {
  .video-list {
    grid-template-columns: repeat(5, 19.8%);
  }
}

