.simple-container header {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.simple-container main {
  padding-top: 60px;
}

footer.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100vw;

}

@media screen and (max-width: 600px) {
  .simple-container .share-page {
    padding-top: 50px;
  }

}